import React from 'react';
import { Page, Navigator, Toolbar, Row, Col, Card, BackButton, List, ListHeader, ListItem, Input, Button } from 'react-onsenui';
import firebase from '../firebase'


class PresentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            presentDatas: []
        }

        this.PRESENT_LIST_LEN = 10
    }

    componentDidMount() {
        this.getAllData();
    }


    onClickPresent(e) {
        this.props.navigator.pushPage({ component: PresentForm }, { animation: "slide" });
    }

    getAllData() {
        var db = firebase.firestore();
        var docRef = db.collection("present").orderBy("name");
        docRef.get().then((query) => {
            console.log(query)
            var buff = [];
            query.forEach((doc) => {
                var data = doc.data();
                buff.push([
                    <Row>
                        <Col>
                            <a href={data.url} target="_blank">
                                <Card style={{ padding: "0px 0px 0px 0px", width: "95%", height: "95%" }} >
                                    <img src={data.img} style={{ width: '100%', height: '100%', margin: 0, padding: 0, 'object-fit': 'cover' }}></img>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                    ,]);
            });
            console.log(buff);
            //名前で昇順
            buff.sort(function (a, b) {
                if (a[2] < b[2]) return -1;
                if (a[2] > b[2]) return 1;
                console.log(a);
                return 0;
            });
            this.setState({ presentDatas: buff })
        })
            .catch((error) => {
                console.log(`データの取得に失敗しました (${error})`);
            });
    }

    render() {

        // スタイル
        var present_content_style = {
            width: '100%',
            height: '100%',
        };
        var present_item_style = {
            width: '95%',
            height: '400px',
        };
        // スタイル

        var presentListItems = []
        for (let i = 0; i < this.PRESENT_LIST_LEN; i++) {
            presentListItems.push(
                <Row>
                    <Col>
                        {<Card onClick={this.onClickPresent.bind(this)} style={present_item_style}>item{i}</Card>}
                        {this.state.presentDatas}
                    </Col>
                </Row>
            )
        }

        return (
            <Page renderToolbar={() => <Toolbar navigator={navigator}>
                <div className="center" style={{ 'font-weight': 'bold' }}></div>
            </Toolbar>}>
                <div style={present_content_style}>
                    <p style={{ padding: "10px" }}>
                        【抽選の詳細】<br />
                        1. 抽選募集は月末２５日から翌月１５日で募集します。<br />
                        2. 抽選参加は当アプリからの申し込み、またはフリーペーパーをお手にした方のメールかハガキから抽選いたします。<br />
                        3. 各商品応募されても　当たるのは1商品のみになります。<br />
                        4. 当選発表は発送をもってかえさせていただきます。<br /><br />
                        ※抽選はFACEが行っているものであり、Apple/Googleとは一切関係ありません。
                    </p>
                    {this.state.presentDatas}
                </div>
            </Page>
        );
    }
}

class PresentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        // スタイル

        // スタイル

        return (
            <Page renderToolbar={() => <Toolbar navigator={navigator}>
                <div className="left">
                    <BackButton onClick={() => this.props.navigator.popPage()}>BACK</BackButton>
                </div>
                <div className="center" style={{ 'font-weight': 'bold' }}>プレゼント</div>
            </Toolbar>}>
                <div>
                    <List>
                        <ListHeader>お名前</ListHeader>
                        <ListItem>
                            <Input type="text" className="form_input" placeholder="お名前" />
                        </ListItem>

                        <ListHeader>年齢</ListHeader>
                        <ListItem>
                            <Input type="text" className="form_input" placeholder="メールアドレス" />
                        </ListItem>


                        <ListHeader>性別</ListHeader>
                        <ListItem>
                            <Input type="text" className="form_input" placeholder="メールアドレス" />
                        </ListItem>


                        <ListHeader>メールアドレス</ListHeader>
                        <ListItem>
                            <Input type="email" className="form_input" placeholder="メールアドレス" />
                        </ListItem>

                        <ListHeader>電話番号</ListHeader>
                        <ListItem>
                            <Input type="text" className="form_input" placeholder="メールアドレス" />
                        </ListItem>

                        <ListHeader>住所</ListHeader>
                        <ListItem>
                            <Input type="text" className="form_input" placeholder="メールアドレス" />
                        </ListItem>

                        <ListHeader>FACEへのメッセージ</ListHeader>
                        <ListItem>
                            <textarea cols="50" rows="10"></textarea>
                        </ListItem>




                    </List>
                </div>
                <div style={{ width: '100%', 'text-align': 'center', 'margin-top': '20px' }}>
                    <Button modifier="" style={{ width: '200px' }}>送信</Button>
                </div>
            </Page>
        );
    }
}




const App = () => (
    <Navigator
        renderPage={renderPage}
        initialRoute={{ component: PresentPage, key: 'PRESENT PAGE' }}
    />
);

const renderPage = (route, navigator) => (
    <route.component key={route.key} navigator={navigator} />
);

export default App;