import React from 'react';
import logo from './logo.svg';
import { Page, Button,Toolbar,Tabbar,Tab, SearchInput,Splitter,SplitterContent,SplitterSide,ProgressCircular} from 'react-onsenui';

import './css/onsenui.css'
import './css/onsen-css-components.css'
import './App.css';

import HomePage from './scripts/HomePage'
import SearchPage from './scripts/SearchPage'
import PresentPage from './scripts/PresentPage'
import ContactPage from './scripts/ContactPage'
import NewsPage from './scripts/NewsPage'

var GENRE_ID ={
  NONE:0,
  GOURMET : 1,
  HAIRSALON : 2,
  BEAUTY : 3,
  RELAXATION : 4,
  SCHOOL : 5,
  SHOPPING : 6,
  OTHER : 7,
}

class TabPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        content :[<div style={{width:'100%',height:'100%','text-align':'center','padding-top':'50px'}}><ProgressCircular indeterminate style={{width:'50px',height:'50px'}} /></div>],
        datas:[],
        shop_data_all:[],
        shop_data_gourmet:[],
        shop_data_hairsalon:[],
        shop_data_beauty:[],
        shop_data_life:[]
    }

  
  }

  componentDidMount(){ //window loadと同様
    this.getShopDatas();
  }

  /*  サーバーへリクエスト  */
  getShopDatas(){
    var url = 'https://aliceproject.sakura.ne.jp/face_app/send_request.php';
    return fetch(url,{method: 'GET',})
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(2);
    this.setState({
        loading: true,
    });
    console.log(responseJson[0])
    this.createShopData(responseJson)
    //console.log(this.createShopDataAll(responseJson))
    })
    .catch((error) =>{
      console.log(3);
        console.error(error);
    });
  }

  shuffleAry(ary){
    var i = ary.length;
    while(i){
      var j = Math.floor(Math.random()*i);
      var t = ary[--i];
      ary[i] = ary[j];
      ary[j] = t;
    }
    return ary;
  }


  /*  ショップデータはここで全て最初に取得  */
  createShopData(datas){
    var shop_datas_list = [];

    var all = this.createShopDataAll(datas);
    all = this.shuffleAry(all);

    var gourmet = this.createShopDataGourmet(datas);
    gourmet = this.shuffleAry(gourmet);

    var hairsalon = this.createShopDataHairsalon(datas);
    hairsalon = this.shuffleAry(hairsalon);

    var beauty = this.createShopDataBeauty(datas);
    beauty = this.shuffleAry(beauty);

    var life = this.createShopDataLife(datas);
    life = this.shuffleAry(life);

    shop_datas_list['all'] = all;
    shop_datas_list['gourmet'] = gourmet;
    shop_datas_list['hairsalon'] = hairsalon;
    shop_datas_list['beauty'] = beauty;
    shop_datas_list['life'] = life;

    this.setState({
      datas:shop_datas_list,
      content:  <Tabbar animation='none' index={0} renderTabs={this.renderTabs}/>,
    })
  }

  /*  全てのショップデータを取得　 */
  createShopDataAll(datas){
    var ret = [];
    console.log(datas)
    for(var i=0; i <datas.length ;i+=1){
      if(datas[i]['shop_show']==1)continue;
      ret.push(
        datas[i],
      )
    }
    
    return ret;
  }

  /*  グルメのショップデータを取得　 */
  createShopDataGourmet(datas){
    var ret = [];
    for(var i=0; i <datas.length ;i+=1){
      if(datas[i]['shop_show']==1 || datas[i]['shop_genre_id']!=GENRE_ID.GOURMET)continue;
      ret.push(
        datas[i],
      )
    }
    return ret;
  }

  /*  ヘアサロンのショップデータを取得　 */
  createShopDataHairsalon(datas){
    var ret = [];
    for(var i=0; i <datas.length ;i+=1){
      if(datas[i]['shop_show']==1 || datas[i]['shop_genre_id']!=GENRE_ID.HAIRSALON)continue;
      ret.push(
        datas[i],
      )
    }
    return ret;
  }

  /*  ビューティーのショップデータを取得　 */
  createShopDataBeauty(datas){
    var ret = [];
    for(var i=0; i <datas.length ;i+=1){
      if(datas[i]['shop_show']==1 || datas[i]['shop_genre_id']!=GENRE_ID.BEAUTY)continue;
      ret.push(
        datas[i],
      )
    }
    return ret;
  }

    /*  ライフのショップデータを取得　 */
    createShopDataLife(datas){
      var ret = [];
      for(var i=0; i <datas.length ;i+=1){
        if(datas[i]['shop_show']==1)continue;
          if(datas[i]['shop_genre_id']==GENRE_ID.RELAXATION || datas[i]['shop_genre_id']==GENRE_ID.SCHOOL || datas[i]['shop_genre_id']==GENRE_ID.SHOPPING || datas[i]['shop_genre_id']==GENRE_ID.OTHER){
            ret.push(
              datas[i],
            )
          }
      }
      return ret;
    }

  /* タブの表示 */
  renderTabs = () => {
    console.log(this.state.datas)
    return [
      {
        content: <HomePage key="Home" shop_datas={this.state.datas}  />,
        tab: <Tab key="Home" label="ホーム" icon="fa-home" />,
      },
      {
        content: <SearchPage key="Serch" shop_datas={this.state.datas}  />,
        tab: <Tab key="Serch" label="探す" icon="fa-search" />,
      },
      {
        content: <NewsPage key="News" />,
        tab: <Tab key="News" label="お知らせ" icon="fa-newspaper" />,
      },
      
      {
        content: <PresentPage key="Present"  />,
        tab: <Tab key="Present" label="プレゼント" icon="fa-gift" />,
      },
      {
        content: <ContactPage key="Contact"  />,
        tab: <Tab key="Contact" label="問い合わせ" icon="fa-envelope" ></Tab>,
      },
    ];
  };
  
  render(){
    return (
      <Page >
        {this.state.content}
      </Page>
    );
  }
}

export default class TopPage extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleRightClose(){

  }

  handleRightOpen(){

  }

  render(){
    return(
    <Splitter>
      {/*<SplitterSide
        side="left"
        width={20}
        isOpen={true}
        swipeable={true}>
        <Page> Page Left </Page>
      </SplitterSide>*/}
      
      <SplitterContent>
        <TabPage/>
      </SplitterContent>
        
      {/*<SplitterSide
        side="right"
        width={300}
        collapse={!this.state.showRight}
        isOpen={true}
        onClose={this.handleRightClose.bind(this)}
        onOpen={this.handleRightOpen.bind(this)}
        isSwipeable={true}>
          <Page> Page Right </Page>
      </SplitterSide>*/}
        </Splitter>
    )
  }
}

//export default App;
