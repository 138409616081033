import React from 'react';
import { Page} from 'react-onsenui';



export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        console.log(this.props.navigator.routes.length)
    }
    render(){
        return (
        <Page>
            <p>test page</p>
        </Page>
        );
    }
}  