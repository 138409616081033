import React from 'react';
import { Page, Toolbar, BackButton, Button, Row, Col, List, ListItem, SearchInput } from 'react-onsenui';

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.rn = this.props.navigator.routes.length - 1;
        this.state = {
            shop_name: this.props.navigator.routes[this.rn].shop_datas['shop_name'],

            shop_image1: this.props.navigator.routes[this.rn].shop_datas['shop_image1'],
            shop_image2: this.props.navigator.routes[this.rn].shop_datas['shop_image2'],

            shop_map_x: this.props.navigator.routes[this.rn].shop_datas['shop_map_x'],
            shop_map_y: this.props.navigator.routes[this.rn].shop_datas['shop_map_y'],
            shop_url: this.props.navigator.routes[this.rn].shop_datas['shop_url'],

            shop_text: this.props.navigator.routes[this.rn].shop_datas['shop_text'],
            shop_address: this.props.navigator.routes[this.rn].shop_datas['shop_address'],
            shop_holiday: this.props.navigator.routes[this.rn].shop_datas['shop_holiday'],
            shop_business_hours: this.props.navigator.routes[this.rn].shop_datas['shop_business_hours'],
            shop_tel: this.props.navigator.routes[this.rn].shop_datas['shop_tel'],
            shop_pr_text: this.props.navigator.routes[this.rn].shop_datas['shop_pr_text'],

            shop_coupon1_title: this.props.navigator.routes[this.rn].shop_datas['shop_coupon1_title'],
            shop_coupon1_period: this.props.navigator.routes[this.rn].shop_datas['shop_coupon1_period'],
            shop_coupon1_term: this.props.navigator.routes[this.rn].shop_datas['shop_coupon1_term'],
            shop_coupon1_detail: this.props.navigator.routes[this.rn].shop_datas['shop_coupon1_detail'],

            shop_coupon2_title: this.props.navigator.routes[this.rn].shop_datas['shop_coupon2_title'],
            shop_coupon2_period: this.props.navigator.routes[this.rn].shop_datas['shop_coupon2_period'],
            shop_coupon2_term: this.props.navigator.routes[this.rn].shop_datas['shop_coupon2_term'],
            shop_coupon2_detail: this.props.navigator.routes[this.rn].shop_datas['shop_coupon2_detail'],

            shop_coupon3_title: this.props.navigator.routes[this.rn].shop_datas['shop_coupon3_title'],
            shop_coupon3_period: this.props.navigator.routes[this.rn].shop_datas['shop_coupon3_period'],
            shop_coupon3_term: this.props.navigator.routes[this.rn].shop_datas['shop_coupon3_term'],
            shop_coupon3_detail: this.props.navigator.routes[this.rn].shop_datas['shop_coupon3_detail'],

            shop_coupon_news_title: this.props.navigator.routes[this.rn].shop_datas['shop_coupon_news_title'],
            shop_coupon_news_period: this.props.navigator.routes[this.rn].shop_datas['shop_coupon_news_period'],
            shop_coupon_news_term: this.props.navigator.routes[this.rn].shop_datas['shop_coupon_news_term'],
            shop_coupon_news_detail: this.props.navigator.routes[this.rn].shop_datas['shop_coupon_news_detail'],

        }
        console.log(this.rn)
        console.log(this.props)
    }

    render() {


        // スタイル
        var shop_content_style = {
            background: '#fff',
            width: '100%',
            height: '100%',
        };

        var shop_exp_style = {
            padding: '10px',
            whiteSpace: 'pre-line',
            background: '#fff',
            overflow: 'hidden',
        }

        var shop_image_area = {
            width: '100%',
            height: '50%',
            background: '#333',
        }

        var shop_button_col_style = {
            padding: '10px',
        }

        var shop_link_style = {
            'text-decoration': 'none',
        }

        // スタイル



        var shop_map_button = (<div></div>);
        var shop_site_button = (<div></div>);
        var shop_map_url = "";

        if (this.state.shop_map_x != "") {
            shop_map_url = "https://maps.google.com/maps?q=" + this.state.shop_map_x + "," + this.state.shop_map_y + "&z=15&t=m&hl=ja&gl=JP&mapclient=embed";
        }

        if (shop_map_url != "") {
            shop_map_button = (
                <a href={shop_map_url} style={shop_link_style} target="_blank">
                    <Button modifier="large">マップ</Button>
                </a>
            )
        } else {
            shop_map_button = (
                <Button modifier="large" disabled>マップ</Button>
            )
        }

        if (this.state.shop_url != "") {
            shop_site_button = (
                <a href={this.state.shop_url} style={shop_link_style} target="_blank">
                    <Button modifier="large">サイト</Button>
                </a>
            )
        } else {
            shop_site_button = (
                <Button modifier="large" disabled>サイト</Button>
            )
        }

        return (
            <Page renderToolbar={() => <Toolbar navigator={this.props.navigator}>
                <div className="left">
                    <BackButton onClick={() => this.props.navigator.popPage()}>BACK</BackButton>
                </div>
                <div className="center">

                </div>
            </Toolbar>}>
                {/*<div style={{ textAlign: "center", border: "0px solid #333", margin: "0px", borderRadius: "10px", background: "#FFCC74", opacity: 1, }}>
                    <h2 style={{ color: "#fff", fontWeight: "bold" }}>{this.state.shop_name}</h2></div>*/}
                <div style={shop_content_style}>

                    <div style={shop_image_area}>
                        <img src={this.state.shop_image1} style={{ width: '100%', height: '100%', margin: 0, padding: 0, 'object-fit': 'cover' }}></img>
                    </div>

                    <div style={shop_exp_style}>
                        <h3 style={{ fontWeight: "bold" }}>{this.state.shop_name}</h3>
                        <List modifier="inset">
                            <ListItem expandable>
                                <div className='left' style={{ fontSize: '20px' }}>FACEクーポン</div>
                                <div className='expandable-content'>

                                    <div style={{ border: '1px solid #E5007F', 'border-radius': '5px', background: '#E5007F' }}>
                                        <div style={{ padding: '5px', color: '#fff' }}>
                                            <div style={{ background: '#E5007F', padding: '5px', 'margin-top': '-5px' }}>{this.state.shop_coupon1_title}</div>
                                            <br />
                                            <div style={{ 'text-align': 'center', background: '#E5007F,', 'font-size': '20px', 'font-weight': 'bold' }}>{this.state.shop_coupon1_detail}</div>
                                            <br />
                                        </div>
                                        <div style={{ background: '#fff', padding: '10px', border: '1px solid #E5007F', 'border-radius': '5px' }}>
                                            <div>{this.state.shop_coupon1_term}</div>
                                            <div>{this.state.shop_coupon1_period}</div>
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ border: '1px solid #E5007F', 'border-radius': '5px', background: '#E5007F' }}>
                                        <div style={{ padding: '5px', color: '#fff' }}>
                                            <div style={{ background: '#E5007F', padding: '5px', 'margin-top': '-5px' }}>{this.state.shop_coupon2_title}</div>
                                            <br />
                                            <div style={{ 'text-align': 'center', background: '#E5007F,', 'font-size': '20px', 'font-weight': 'bold' }}>{this.state.shop_coupon2_detail}</div>
                                            <br />
                                        </div>
                                        <div style={{ background: '#fff', padding: '10px', border: '1px solid #E5007F', 'border-radius': '5px' }}>
                                            <div>{this.state.shop_coupon2_term}</div>
                                            <div>{this.state.shop_coupon2_period}</div>
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ border: '1px solid #E5007F', 'border-radius': '5px', background: '#E5007F' }}>
                                        <div style={{ padding: '5px', color: '#fff' }}>
                                            <div style={{ background: '#E5007F', padding: '5px', 'margin-top': '-5px' }}>{this.state.shop_coupon3_title}</div>
                                            <br />
                                            <div style={{ 'text-align': 'center', background: '#E5007F,', 'font-size': '20px', 'font-weight': 'bold' }}>{this.state.shop_coupon3_detail}</div>
                                            <br />
                                        </div>
                                        <div style={{ background: '#fff', padding: '10px', border: '1px solid #E5007F', 'border-radius': '5px' }}>
                                            <div>{this.state.shop_coupon3_term}</div>
                                            <div>{this.state.shop_coupon3_period}</div>
                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        </List>

                        <Row>
                            <Col style={shop_button_col_style}>
                                {shop_map_button}
                            </Col>

                            <Col style={shop_button_col_style}>
                                {shop_site_button}
                            </Col>
                        </Row>



                        <p>{this.state.shop_text}</p>

                        <p>
                            <div style={{ 'font-weight': 'bold' }}>住所</div>
                            <div>{this.state.shop_address}</div>
                        </p>
                        <p>
                            <div style={{ 'font-weight': 'bold' }}>定休日</div>
                            <div>{this.state.shop_holiday}</div>
                        </p>
                        <p>
                            <div style={{ 'font-weight': 'bold' }}>営業時間</div>
                            <div>{this.state.shop_business_hours}</div>
                        </p>
                        <p>
                            <div style={{ 'font-weight': 'bold' }}>電話番号</div>
                            <div>{this.state.shop_tel}</div>
                        </p>
                        <p>
                            <div style={{ 'font-weight': 'bold' }}>PR</div>
                            <div>{this.state.shop_pr_text}</div>
                        </p>

                    </div>
                </div>
            </Page>
        );
    }
}  
