import React from 'react';
import { Page,Toolbar,BackButton,Button,Row,Col,List,ListItem,Card, SearchInput,ProgressCircular} from 'react-onsenui';

import ShopContent from './ShopContent';



export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shop_datas : this.props.navigator.routes[1].shop_datas,
            listItems :[<div style={{width:'100%',height:'100%','text-align':'center','padding-top':'50px'}}><ProgressCircular indeterminate style={{width:'50px',height:'50px'}} /></div>]
        }

        this.RECOMMEND_LIST_LEN = 10
    }

    componentDidMount(){ //window loadと同様

        this.createListItem();
    }

    onClick(shop_name,datas,e){

        this.props.navigator.pushPage({
            component: ShopContent,
            title:shop_name,
            shop_datas: datas,
        }, {animation: "fade"}); 
    }

    createListItem(){
        var list = [];
        const ldates = this.state.shop_datas;
        for(var i=0; i <ldates.length ;i+=1){
            if(ldates[i]['shop_reco_shop_flg']==null || ldates[i]['shop_reco_shop_flg']==0 || ldates[i]['shop_show']==1)continue;
            var shop_name = ldates[i]['shop_name'];
            var shop_image_thum = ldates[i]['shop_image_thum'];
            list.push(
            <Row>
                <Col >
                <Card onClick={this.onClick.bind(this,shop_name, ldates[i])} style={{width:'95%',height:'95%',padding:0}} >
                    <img src={shop_image_thum } style={{width:'100%',height:'100%',margin:0,padding:0,'object-fit':'cover'}} ></img>
                </Card>
                </Col>
            </Row>
            )
        }
        this.setState(()=>{
            return{listItems:list};
        });
    }
    
    render(){


        // スタイル
        var recommend_content_style={
            width:'100%',
            height:'100%',
        };
        var recommend_item_style={
            width:'95%',
            height:'400px',
        };
        // スタイル

        var recommendListItems = []
        for (let i = 0; i < this.RECOMMEND_LIST_LEN; i++) {
            recommendListItems.push(
                <Row>
                <Col>
                <Card style={recommend_item_style}>item{i}</Card>
                </Col>
                </Row>
            )
        }

        return (
        <Page renderToolbar ={()=> <Toolbar navigator={this.props.navigator}>
            <div className="left">
                <BackButton onClick={() => this.props.navigator.popPage()}>BACK</BackButton>
            </div>
            <div className="center">
                {this.state.shop_name}
            </div>
            </Toolbar>}>
            <div style={recommend_content_style}>
                {this.state.listItems}
            </div>
        </Page>
        );
    }
}  
