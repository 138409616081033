import React from 'react';
import { Page,Toolbar,SearchInput,Carousel,CarouselItem,Splitter,SplitterSide,SplitterContent,
          Row,Col,Card,Button,Toast,Navigator,ProgressCircular,Input} from 'react-onsenui';
//import './css/onsenui.css'
//import './css/onsen-css-components.css'
import ReccomendList from './RecommendListPage'
import ShopContent from './ShopContent'

class HomeToolbar extends React.Component {
  render() {
    return (
      <Toolbar>
      </Toolbar>
    );
  }
}

class HomeContent extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return(
      <Page renderToolbar ={()=> <Toolbar navigator={navigator}>
      </Toolbar>}>
      <div style={{height: '100%'}}>
        {<HomeCarousel navigator={this.props.navigator} shop_datas={this.props.shop_datas} />}
        {<HomeRecommend navigator={this.props.navigator} shop_datas={this.props.shop_datas} />}
      </div>
      </Page>
    )
  }
}



class HomeCarousel extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
        cIndex:0,
        loading:false,
        cListItem:[<div style={{width:'100%',height:'100%','text-align':'center','padding-top':'50px'}}><ProgressCircular indeterminate style={{width:'50px',height:'50px'}} /></div>],
    }
    this.CAROUSEL_ITEM_LEN = 5;
    this.ROLE_TIME = 5000;

    //this.createListItem();
    this.startTimer();
  }


  onClickImg(datas){
    this.props.navigator.pushPage({
      component: ShopContent,
      shop_datas: datas,
    }, {animation: "fade"}); //アニメーションはslide lift fade none*/
  }

  carouselNext(){
    var n = this.state.cIndex + 1;
    if(n >= this.CAROUSEL_ITEM_LEN ){
      n = 0
    }
    this.setState({cIndex:n})
    console.log("next")
  }

  startTimer () {
    this.intervalTimer = setInterval(() => this.carouselNext(), this.ROLE_TIME)
  }

  getShopDatas(){
    this.createListItem(this.props.shop_datas['all']);
  }

  
  createListItem(datas){

    // スタイル
    var cal_content_style = {
      width:'100%',
      height:'100%',
      position: 'relative',
    }
    var cal_img_style = {
      width:'100%',
      height:'100%',
      'object-fit': 'cover',
    }
    var cal_txt_style = {
      position: 'absolute',
      color: 'white',
      opacity:0.3,
      'font-weight': 'bold',
      'font-size': '1.5em',
      //top: '0%',
      //left: '0%',
      right:0,
      bottom:0,
      //'-ms-transform': 'translate(-50%,-50%)',
      //'-webkit-transform': 'translate(-50%,-50%)',
      //'transform': 'translate(-50%,-50%)',
      margin:'0',
      padding: '5px 10px',
      //background: 'skyblue',
      'text-stroke': '1px #333',
      '-webkit-text-stroke': '1px #333',
      'font-family' :'Quicksand,sans-serif',
      'background': '#333',
      border:'1px solid #fff',
      'border-radius': '10px 0px 0px 0px',
    }

    const list = [];
    var sDatas = datas;
    var n = 0;
    var m =  Object.keys(sDatas).length;
    var imgUrl = "";
    var shopName = "";
    for (let i = 0; i < this.CAROUSEL_ITEM_LEN; i++) {
      n = Math.floor(Math.random() * m)
      if(sDatas[n]['shop_show'] == 1){
        i--;
        continue
      }
      imgUrl = sDatas[n]['shop_image1'];
      shopName = sDatas[n]['shop_name'];
      list.push(
        <CarouselItem onClick={this.onClickImg.bind(this,sDatas[n])} style={{backgroundColor: 'gray'}}>
          <div style={cal_content_style}>
            <img src={imgUrl} style={cal_img_style}/>
            <div style={cal_txt_style}>{shopName}</div>
          </div>
        </CarouselItem>
      )
    }
    this.cListItem = list;
    this.setState({cListItem:list})
  }

  componentDidMount(){ //window loadと同様
    this.getShopDatas();
  }

  render(){
    
    return(
      <div style={{height: '45%'}}>
      <Carousel
        onPostChange={() => console.log('onPostChange')}
        onOverscroll={() => console.log('onOverscroll')}
        onRefresh={() => console.log('onRefresh')}
        ref={(carousel) => { 
          this.carousel = carousel;
          console.log(this.carousel);
          console.log("ref");
        }}
        //swipeable
        overscrollable
        autoScroll
        fullscreen
        autoScrollRatio={0.2}
        index = {this.state.cIndex}> 
        {this.state.cListItem}
      </Carousel>
      </div>
    )
  }
}

class HomeRecommend extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      shop_datas : [
        this.props.shop_datas['all'],
        this.props.shop_datas['gourmet'],
        this.props.shop_datas['hairsalon'],
        this.props.shop_datas['beauty'],
        this.props.shop_datas['life'],
      ],
      recommend_shop_datas :[],
      recommend_shop_random :[],
    }

    var reccomendAllItem1 = "";
    var reccomendAllItem2 = "";

    var reccomendGourmetItem1 = "";
    var reccomendGourmetItem2 = "";

    this.createRecommendItems();

  }

  componentDidMount(){ //window loadと同様
    //this.createRecommendItems();
  }
  
  onClickPushPage(shop_datas,e){
    this.props.navigator.pushPage({
      component: ReccomendList,
      shop_datas: shop_datas,
    }, {animation: "slide"}); //アニメーションはslide lift fade none
  }

  
  onClickItem(datas,e){
    this.props.navigator.pushPage({
        component: ShopContent,
        shop_datas: datas,
    }, {animation: "fade"}); 
}

  createRecommendItems(){
    
    for(var i=0; i < this.state.shop_datas.length; i++){
      var sd = this.state.shop_datas[i];
      var sitem = [];
      for(var i2 = 0; i2 < sd.length; i2++){
        var sd2 = sd[i2];
        if(sd2['shop_reco_shop_flg']==null || sd2['shop_reco_shop_flg']==0)continue;
        sitem.push(
          sd2,
        )
      }
      this.state.recommend_shop_datas.push(
        sitem
      )
      this.createRecommendRandomItem(i)
    }
  }

  createRecommendRandomItem(num){
    var rItems = [(<div></div>),(<div></div>)]

    var m =  Object.keys(this.state.recommend_shop_datas[num]).length;
    var n1 = Math.floor(Math.random() * m) 
    var n2 = Math.floor(Math.random() * m)

    
    if(n1 == n2){
      //n2++;
      //if(n2 > m)n2 = 0;
      var cnt = 0;
      while(n1 == n2){
        n2 = Math.floor(Math.random() * m)
        cnt ++;
        if(cnt > 100) break;
      }
      //n2 = Math.floor(Math.random() * m)
    }


    //return
    var item1 = this.state.recommend_shop_datas[num][n1];
    var item2 = this.state.recommend_shop_datas[num][n2];

    if(n1 == n2) item2 = false;

    if(!item1 == false){
      rItems[0] = (
        <img onClick={this.onClickItem.bind(this,item1)} 
        src={item1['shop_image_thum']} 
        style={{width:'100%',height:'100%',margin:0,padding:0,'object-fit':'cover'}} />
        )
    }

    if(!item2 == false){
      rItems[1] = (
        <img onClick={this.onClickItem.bind(this,item2)} 
        src={item2['shop_image_thum']} 
        style={{width:'100%',height:'100%',margin:0,padding:0,'object-fit':'cover'}} />
        )
    }
  
    this.state.recommend_shop_random.push(rItems);
    
    
  }

  reccomendDomCreate(num){
    // スタイル
    var recommend_item_style={
          width:'95%',
          height:'95%',
          padding:'0px'
    };

    var genre_list = [
      "おすすめ",
      "グルメ",
      "ヘアサロン",
      "ビューティー",
      "ライフ",
    ]

    var ret = 
    (<div>
        <hr width="90%" style={{height:"1px","background-color":"#e3e3e3",border:'none'}}/>
    <div>
      <Row>
        <Col style={{'vertical-align':'middle',margin: 'auto'}}><p style={{'vertical-align':'middle','padding':'0px 0px 0px 10px'}}><h5>{genre_list[num]}</h5></p></Col>
        <Col style={{margin: 'auto'}}><p style={{'text-align':'right','vertical-align':'middle'}}><Button onClick={this.onClickPushPage.bind(this,this.state.recommend_shop_datas[num])} modifier="quiet">もっと見る></Button></p></Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Card style={recommend_item_style}>
          {this.state.recommend_shop_random[num][0]}
        </Card>
      </Col>
      <Col>
        <Card style={recommend_item_style}>
          {this.state.recommend_shop_random[num][1]}
        </Card>
      </Col>
    </Row>
    </div>)
    return ret
  }

  render(){

    return(
      <div style={{background:'#fff','margin-top':'20px'}}>
        {this.reccomendDomCreate(0)}
        {this.reccomendDomCreate(1)}
        {this.reccomendDomCreate(2)}
        {this.reccomendDomCreate(3)}
        {this.reccomendDomCreate(4)}
      </div>
    )
  }
}


class HomePage extends React.Component 
{
  constructor(props) {
    super(props);
  }
  render() 
  {
    return(
    <Page>
      <HomeContent navigator={this.props.navigator} shop_datas={this.props.navigator.props.shop_datas['shop_datas']} />
    </Page>
    );
  }
}



/*  ページの表示  */
const App = (shop_datas) =>(
  <Navigator
  renderPage={renderPage}
  initialRoute={{component: HomePage, key: 'HOME PAGE'}} shop_datas={shop_datas}
  />
);

const renderPage = (route, navigator) => (
  <route.component key={route.key} navigator={navigator} />
);

export default App;