import React from 'react';
import { Page, Navigator, Toolbar, List, ListHeader, ListItem, Input, SearchInput, Tab, Tabbar, Button, Popover, Dialog, ProgressCircular } from 'react-onsenui';
import HomePage from './HomePage'
import PresentPage from './PresentPage'
import SearchPage from './SearchPage'



class ContactPage extends React.Component {

    render() {
        return (
            <Page renderToolbar={() => <Toolbar navigator={navigator}>
                <div className="center" style={{ 'font-weight': 'bold' }}></div>
            </Toolbar>}>
                <ContactForm />
            </Page>
        );
    }
}

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendName: "",
            sendAddr: "",
            sendText: "",
            resMsg: "",
            resDialog: "",
            isOpenDialog: false,
            isOpenPopName: false,
            isOpenPopAddr: false,
            isOpenPopText: false,
        }
    }

    cancelDialog(e) {
        this.setState({ isOpenDialog: false })
    }

    changeInputName(e) {
        this.setState({ sendName: e.target.value })
    }

    changeInputAddr(e) {
        this.setState({ sendAddr: e.target.value })
    }

    changeInputText(e) {
        this.setState({ sendText: e.target.value })
    }

    sendMail() {
        if (this.checkSendData() == false) return;

        this.setState({ resMsg: "" })
        this.setState({ isOpenDialog: true })
        this.setState({ resDialog: <ProgressCircular indeterminate /> })
        fetch('http://160.16.126.80:4002/', {
            method: 'POST',
            body: JSON.stringify({
                fromAddr: 'aliceproject0704@gmail.com',
                toAddr: 'ryu525252@gmail.com',
                ccAddr: 'aliceproject0704@gmail.com',
                sbj: 'FACE問い合わせ',
                msg: this.createSendMsg(),
            }),
            headers: new Headers(
                {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                })
        }).then(response => response.text())
            .then(data => {
                console.log('Success:', data);
                this.setState({ resMsg: data })
            }).catch((error) => {
                console.error('Error:', error);
                this.setState({ resMsg: error })
            }).finally(() => {
                var res =
                    <div>
                        <div>{this.state.resMsg}</div>
                        <div>
                            <Button modifier="" style={{ width: '100px', marginTop: "10px" }}
                                ref={(btn) => { this.btn = btn; }}
                                onClick={() =>
                                    this.cancelDialog()
                                }>
                                閉じる
                            </Button>
                        </div>
                    </div>
                this.setState({ resDialog: res })
            })
    }

    checkSendData() {
        var ret = false;
        if (this.state.sendName == "") {
            this.setState({ isOpenPopName: true });
        }
        return ret;
    }

    createSendMsg() {
        var ret = "";
        ret += "<p>" + "お名前" + "</p>";
        ret += "<p>" + this.state.sendName + "</p>";
        ret += "<p>" + "メールアドレス" + "</p>";
        ret += "<p>" + this.state.sendAddr + "</p>";
        ret += "<p>" + "お問い合わせ内容" + "</p>";
        ret += "<p>" + this.state.sendText + "</p>";


        return ret;
    }

    render() {
        return (
            /*<Page>
                <div>
                    <List>
                        <ListHeader>お名前</ListHeader>
                        <ListItem>
                            <Input type="text" ref={(btn) => { this.inputName = btn; }} className="form_input" placeholder="お名前" value={this.state.sendName} onChange={this.changeInputName.bind(this)} />
                        </ListItem>

                        <ListHeader>メールアドレス</ListHeader>
                        <ListItem>
                            <Input type="email" className="form_input" placeholder="メールアドレス" value={this.state.sendAddr} onChange={this.changeInputAddr.bind(this)} />
                        </ListItem>

                        <ListHeader>お問い合わせ内容</ListHeader>
                        <ListItem>
                            <textarea cols="50" rows="10" value={this.state.sendText} onChange={this.changeInputText.bind(this)} />
                        </ListItem>
                    </List>
                </div>
                <div style={{ width: '100%', 'text-align': 'center', 'margin-top': '20px' }}>
                    <Button modifier="" style={{ width: '200px' }}
                        ref={(btn) => { this.btn = btn; }}
                        onClick={() =>
                            this.sendMail()
                        }
                    >
                        送信
                    </Button>
                    <Popover
                        direction="down"
                        isOpen={this.state.isOpenPopName}
                        onCancel={() => this.setState({ isOpenPopName: false })}
                        getTarget={() => this.inputName}
                    >
                        <div style={{ textAlign: 'center', opacity: 1 }}>
                            <p><small>お名前を入力してください</small></p>
                        </div>
                    </Popover>
                    <Dialog onCancel={this.cancelDialog.bind(this)}
                        isOpen={this.state.isOpenDialog}
                        cancelable
                    //style={{height:250}}
                    >
                        <Page>
                            <div style={{ 'text-align': 'center', 'padding': '10px' }}>
                                {this.state.resDialog}
                            </div>
                        </Page>
                    </Dialog>
                </div>
            </Page>*/

            <Page>
                <div style={{ width: '100%', 'text-align': 'center', 'margin-top': '20px' }}>
                    <a href={'https://forms.gle/92qe7UdWnZAqFuuQ6'} style={{ 'text-decoration': 'none' }} target="_blank">
                        <Button modifier="" style={{ width: '200px' }}>
                            問い合わせページへ
                        </Button>
                    </a>
                </div>
            </Page>
        );
    }
}


const App = () => (
    <Navigator
        renderPage={renderPage}
        initialRoute={{ component: ContactPage, key: 'CONTACT PAGE' }}
    />
);

const renderPage = (route, navigator) => (
    <route.component key={route.key} navigator={navigator} />
);

export default App;
