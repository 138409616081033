import React from 'react';
import {Page,Navigator,Button,Toolbar,BackButton,List,ListItem,Row,Col,Card,ProgressCircular,SearchInput,ListTitle} from 'react-onsenui';

import ShopContent from './ShopContent';
import TestPage from './TestPage'

const categoryButtons = [
    "images/category_button1.jpg",
    "images/category_button2.jpg",
    "images/category_button3.jpg",
    "images/category_button4.jpg",
    "images/category_button5.jpg",
]


class CategoryList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            shop_datas : [
                this.props.shop_datas['all'],
                this.props.shop_datas['gourmet'],
                this.props.shop_datas['hairsalon'],
                this.props.shop_datas['beauty'],
                this.props.shop_datas['life'],
            ]
        }
    }

    render(){
        return(
            <Page renderToolbar ={()=> <Toolbar navigator={this.props.navigator}>
            </Toolbar>}>
            <List
            dataSource={['全て', 'グルメ','ヘアサロン','ビューティー','ライフ']}
            renderRow={(row, idx) => (
            <ListItem modifier="chevron" tappable  onClick={() => {
                //navigator.pushPage({component: c1,props:{v1: 'v1aaaaaaaaaaaa'}}, {animation: "fade"});
                this.props.navigator.pushPage({component: CategoryPageClass,title:row,shop_datas:(this.state.shop_datas[idx]) }, {animation: "slide"}); //アニメーションはslide lift fade none
            }}>
            {/*<ListTitle>{row}</ListTitle>*/}
            <ListTitle><img style={{"max-width": "95%"}} src={categoryButtons[idx]}></img></ListTitle>
            </ListItem>
            )}
            />
            </Page>
        )
    }
}


class CategoryPageClass extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            shop_datas : this.props.navigator.routes[1].shop_datas,
            title: this.props.navigator.routes[1].title,
            listItems :[<div style={{width:'100%',height:'100%','text-align':'center','padding-top':'50px'}}><ProgressCircular indeterminate style={{width:'50px',height:'50px'}} /></div>]
        }
    }

    componentDidMount(){
        this.createdom();
    }

    onClick(shop_name,datas,e){

        this.props.navigator.pushPage({
            component: ShopContent,
            title:shop_name,
            shop_datas: datas,
        }, {animation: "fade"}); 
    }

    createdom(){
        var list = [];
        const ldates = this.state.shop_datas;
        console.log(ldates[1])
        for(var i=0; i <ldates.length ;i+=2){
            var pushItem1  = (<Col></Col>);
            var pushItem2  = (<Col></Col>);

            var shop_name = ldates[i]['shop_name'];
            var shop_image1 = ldates[i]['shop_image_thum'];

            pushItem1 = (
                <Col style={{height:'300px'}}>
                <Card onClick={this.onClick.bind(this,shop_name, ldates[i])} style={{width:'95%',height:'95%',padding:0}} >
                    <img src={shop_image1} style={{width:'100%',height:'100%',margin:0,padding:0,'object-fit':'cover'}} ></img>
                </Card>
                </Col>
            )

            if(i + 1 < ldates.length ){
                var shop_name2 = ldates[i+1]['shop_name'];
                var shop_image1_2 = ldates[i+1]['shop_image_thum'];

                pushItem2 = (
                    <Col style={{height:'300px'}}>
                    <Card onClick={this.onClick.bind(this,shop_name2 ,ldates[i+1])} style={{width:'95%',height:'95%',padding:0}} >
                        <img src={shop_image1_2} style={{width:'100%',height:'100%',margin:0,padding:0,'object-fit':'cover'}}></img>
                    </Card>
                    </Col>
                )
            }
            

            list.push(
            <Row>
                {pushItem1}
                {pushItem2}
            </Row>
            )
        }
        this.setState(()=>{
            return{listItems:list};
        });
    }



    render(){
        return(
            <Page renderToolbar ={()=> <Toolbar navigator={this.props.navigator}>
            <div className="left"><BackButton onClick={() => this.props.navigator.popPage()}>BACK</BackButton></div>
            </Toolbar>}>
            <p>{this.state.title}</p>
            <div>{this.state.listItems}</div>
        </Page>  
        )
    }
}


class SearchPage extends React.Component{
    
    constructor(props){
        super(props);
    }

    render(){
        return(
            <CategoryList navigator={this.props.navigator} shop_datas={this.props.navigator.props.shop_datas['shop_datas']}/>
        )
    }
}


/*  ページの表示  */
const App = (shop_datas) =>(
    <Navigator
    renderPage={renderPage}
    initialRoute={{component: SearchPage, key: 'CATEGORY PAGE'}} shop_datas={shop_datas}
    />
);

const renderPage = (route, navigator) => (
    <route.component key={route.key} navigator={navigator} />
);


export default App;