import React from 'react';
import { Page, Navigator, Toolbar, Row, Col, Card, BackButton, List, ListHeader, ListItem, Input, Button } from 'react-onsenui';
import firebase from '../firebase'




class NewsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsDatas: []
        }
    }

    truncate(str, len) {
        return str.length <= len ? str : (str.substr(0, len) + "...");
    }

    componentDidMount() {
        this.getAllData();
    }

    getAllData() {
        var buff = []

        var db = firebase.firestore();
        var docRef = db.collection("news").orderBy("date", "desc");

        docRef.get().then((query) => {
            console.log(query)
            var buff = [];
            query.forEach((doc) => {
                var data = doc.data();
                buff.push([
                    <ListItem modifier="longdivider" onClick={this.onClickNewsList.bind(this, data.title, data.text, data.date)}>
                        {/*<div class="left">
                            <img class="list-item__thumbnail" src="https://placekitten.com/g/40/40"/>
                </div> */}
                        <div class="center">
                            <span class="list-item__title" style={{ 'font-weight': 'bold' }}>{data.title}</span>
                            <span class="list-item__subtitle">{this.truncate(data.text, 35)}</span>
                        </div>
                    </ListItem>])
            });

            this.setState({ newsDatas: buff })
        })
            .catch((error) => {
                console.log(`データの取得に失敗しました (${error})`);
            });

    }

    onClickNewsList(title, text, date, e) {
        this.props.navigator.pushPage({
            component: NewsContent,
            title: title,
            text: text,
            date: date,
        }, { animation: "fade" });
    }


    render() {

        // スタイル

        // スタイル

        return (
            <Page renderToolbar={() => <Toolbar navigator={navigator} >
                <div className="center" style={{ 'font-weight': 'bold' }}>
                </div>
            </Toolbar>}>
                <div>
                    <List>
                        <ListHeader></ListHeader>
                        {this.state.newsDatas}
                    </List>
                </div>
            </Page>
        );
    }
}




const App = () => (
    <Navigator
        renderPage={renderPage}
        initialRoute={{ component: NewsList, key: 'PRESENT PAGE' }}
    />
);

const renderPage = (route, navigator) => (
    <route.component key={route.key} navigator={navigator} />
);

export default App;




class NewsContent extends React.Component {

    constructor(props) {
        super(props);
        this.rn = this.props.navigator.routes.length - 1;
        this.state = {
            title: this.props.navigator.routes[this.rn].title,
            text: this.props.navigator.routes[this.rn].text,
            date: this.props.navigator.routes[this.rn].date
        }
    }

    convertDate(_timestamp) {
        var _d = new Date(_timestamp)

        var Y = _d.getFullYear();
        var m = ("0" + (_d.getMonth() + 1)).slice(-2);
        var d = ("0" + _d.getDate()).slice(-2);
        var H = ("0" + _d.getHours()).slice(-2);
        var i = ("0" + _d.getMinutes()).slice(-2);
        var s = ("0" + _d.getSeconds()).slice(-2);

        return `${Y}/${m}/${d} ${H}:${i}:${s}`;
    }


    render() {
        return (
            <Page renderToolbar={() => <Toolbar navigator={navigator}>
                <div className="left">
                    <BackButton onClick={() => this.props.navigator.popPage()}>BACK</BackButton>
                </div>
                <div className="center" style={{ 'font-weight': 'bold' }}></div>
            </Toolbar>}>
                <div>
                    <div style={{ "margin": "10px", "fontSize": "20px", 'font-weight': 'bold' }}>{this.state.title}</div>
                    <div align="right" style={{ "margin": "10px", "fontSize": "14px" }}>{this.convertDate(this.state.date)}</div>
                    <div style={{ "margin": "10px", "fontSize": "20px", "white-space": "pre" }}>{this.state.text}</div>
                </div>
            </Page>
        );
    }

}


